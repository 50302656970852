import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private apiUri = `${environment.apiUri}/translations`;  // Base URL for translations API

  constructor(
    private translateService: TranslateService,
    private http: HttpClient
  ) {
    // Initialize supported languages
    this.translateService.addLangs(['en', 'pl', 'de']);
    const browserLang = this.translateService.getBrowserLang() || 'en';  // Fallback to 'en' if browserLang is undefined
    const defaultLang = ['en', 'pl', 'de'].includes(browserLang) ? browserLang : 'en';  // Ensure supported languages only
    this.translateService.setDefaultLang(defaultLang);
    this.setLanguage(defaultLang);  
  }

  /**
   * Load translations for a specific language from the backend.
   * @param lang Language code
   * @returns Observable of translations data
   */
  loadTranslationsFromBackend(lang: string): Observable<any> {
    return this.http.get(`${this.apiUri}/${lang}`);
  }

  /**
   * Set the selected language and update ngx-translate to use that language.
   * @param lang Language code
   */
  setLanguage(lang: string): void {
    this.translateService.use(lang);  // Change the language in ngx-translate
    sessionStorage.setItem('language', lang);  // Store the selected language locally
  
    // Make an API call to save the language preference
  }
  
  // saveUserLanguagePreference(userId: number, lang: string): Observable<any> {
  //   return this.http.put(`${environment.apiUri}/user/${userId}/language`, { language: lang });
  // }

  /**
   * Get the selected language from session storage or default language.
   */
  getSelectedLanguage(): string {
    return sessionStorage.getItem('language') || this.translateService.getDefaultLang();
  }

  /**
   * Save updated translations to the backend
   * @param lang Language code
   * @param translations Updated translations object
   * @returns Observable for saving translations
   */
  saveTranslationsToBackend(lang: string, translations: any): Observable<any> {
    console.log('saveTranslationsToBackend ',lang)
    return this.http.put(`${this.apiUri}/${lang}`, translations);
  }
}
