<div class="modal-header">
  <h4 class="modal-title">{{ 'TOKEN_EXPIRED.MODAL_TITLE' | translate }}</h4>
  <button type="button" class="btn-close" [attr.aria-label]="'TOKEN_EXPIRED.CLOSE_BUTTON_ARIA_LABEL' | translate" (click)="close('cancel')"></button>
</div>
<div class="modal-body">
  <p>{{ 'TOKEN_EXPIRED.MODAL_BODY_TEXT' | translate }}</p>
  <p id="timer">{{ 'TOKEN_EXPIRED.MODAL_TIMER_TEXT' | translate:{countdown: countdown} }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close('cancel')">{{ 'TOKEN_EXPIRED.BUTTON_LOGOUT' | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="close('extend')">{{ 'TOKEN_EXPIRED.BUTTON_EXTEND_SESSION' | translate }}</button>
</div>
