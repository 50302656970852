import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule, NgbToast, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
 
import { AuthService } from './modules/auth/services/auth.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { FakeAPIService } from './_fake/fake-api.service';
import { AuthInterceptor } from './_core/_interceptors/auth.interceptor';
import { TimezoneDatePipe } from './_metronic/shared/pipes/timezone-date.pipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { of } from 'rxjs';
import { HttpLoaderFactory } from './pages/translations/http-loader-factory';
import { ToastrModule } from 'ngx-toastr';
//import { CustomTranslateLoader } from './pages/translations/custom-translate-loader';

// const config: SocketIoConfig = {
//   url: 'wss://frontdev.voicebot.crem.support',
//   options: {}
// };

// export class CustomTranslateLoader implements TranslateLoader {
//   getTranslation(lang: string) {
//     switch (lang) {
//       case 'pl': return of(pl.data);  // Polish translations
//       case 'de': return of(de.data);  // German translations
//       default: return of(en.data);    // Default to English
//     }
//   }
// }


function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    //SocketIoModule.forRoot(config), 
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgbToastModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [TimezoneDatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
