import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TokenExpiredModalComponent } from 'src/app/_metronic/layout/components/modals/token-expired-modal/token-expired-modal.component';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private modalRef: NgbModalRef | null = null;
  private isRefreshing = false;
  private refreshCooldown = 30000;
  private lastRefresh = 0;

  constructor(
    private modalService: NgbModal,
    private injector: Injector
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/auth/login') || req.url.endsWith('/auth/register') || req.url.endsWith('/auth/refresh-token')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          const now = Date.now();
          const token = this.getTokenFromCookies();
          if (token && this.isTokenExpired(token)) {
            if (!this.isRefreshing && now - this.lastRefresh > this.refreshCooldown) {
              this.lastRefresh = now;
              return this.handleAuthError(req, next);
            }
          }
          return throwError(() => error);
        }
        return throwError(() => error);
      })
    );
  }

  private getTokenFromCookies(): string | null {
    const cookieName = 'token';
    const matches = document.cookie.match(new RegExp(`(?:^|;\\s*)${cookieName}=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  private isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const now = Date.now() / 1000;
      return decoded.exp < now || decoded.exp - now < 60;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  }

  private handleAuthError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    this.isRefreshing = true;

    this.modalRef = this.modalService.open(TokenExpiredModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });

    return new Observable(observer => {
      const timeoutId = setTimeout(() => {
        this.modalRef?.close();
        authService.logout(true);
        observer.error('Session expired - logged out.');
      }, 30000);

      this.modalRef?.result.then(
        (result) => {
          clearTimeout(timeoutId);
          this.isRefreshing = false;

          if (result === 'extend') {
            authService.refreshToken().subscribe(
              (newToken) => {
                const clonedReq = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${newToken}`,
                  },
                });
                next.handle(clonedReq).subscribe(observer);
              },
              () => {
                this.modalRef?.close();
                authService.logout(true);
                observer.error('Failed to refresh token.');
              }
            );
          } else {
            this.modalRef?.close();
            authService.logout(true);
            observer.error('User chose to logout.');
          }
        },
        () => {
          clearTimeout(timeoutId);
          this.modalRef?.close();
          authService.logout(true);
          observer.error('Session expired - logged out.');
        }
      );
    });
  }
}