import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserUpdateService {
  private userSubject = new BehaviorSubject<string | null>(this.getSavedUserUrl());

  constructor() {}

  updateUserUrl(newUserUrl: string): void {
    this.userSubject.next(newUserUrl);
    sessionStorage.setItem('name', newUserUrl); // Save the new user URL in sessionStorage
  }

  getUserUrl(): Observable<string | null> {
    return this.userSubject.asObservable();
  }

  resetUserUrl(): void {
    this.userSubject.next(null);
    sessionStorage.removeItem('name'); // Remove the user URL from sessionStorage
  }

  private getSavedUserUrl(): string | null {
    return sessionStorage.getItem('name');
  }
}
