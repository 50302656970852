import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, catchError, finalize, map, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";

interface Campaign {
  id: number;
  title: string;
  owner: number;
  public: string;
  created: string;
  modified: string;
  status: number;
  phone: string;
  answers: string;
  duration: number;
  module?: string;
}

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  private apiBaseUri = `${environment.apiUri}/campaigns`;
  private apiUserBaseUri = `${environment.apiUri}/user`;
  private apiBaseStatisticUri = `${environment.apiUri}`;

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private selectedCampaign = new BehaviorSubject<any>(null);
  selectedCampaign$ = this.selectedCampaign.asObservable();

  constructor(private http: HttpClient) {}

  startLoading(): void {
    this.isLoadingSubject.next(true);
  }

  stopLoading(): void {
    this.isLoadingSubject.next(false);
  }

  getCampaigns(userId: string): Observable<{ data: Campaign[] }> {
    const url = `${this.apiBaseUri}/user/${userId}`;
    console.log('url ',url)
    return this.http
      .get<{ data: Campaign[] }>(url, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  createCampaign(newCampaign: { user_id: number; workspace_id: number; name: string }): Observable<any> {
    this.startLoading();
    return this.http.post<any>(this.apiBaseUri, newCampaign, { withCredentials: true }).pipe(
      catchError(this.handleError),
      finalize(() => this.stopLoading())
    );
  }


  updateCampaign(campaignId: number, updatedCampaign: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUri}/${campaignId}`,
      updatedCampaign,
      { withCredentials: true }
    );
  }

  deleteCampaign(campaignId: number): Observable<any> {
    return this.http
      .delete<any>(`${this.apiBaseUri}/${campaignId}`, {
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }

  getCampaignsByWorkspaceId(workspaceId: number): Observable<{ data: Campaign[] }> {
    const url = `${this.apiBaseUri}/workspace/${workspaceId}`;
    return this.http
      .get<{ data: Campaign[] }>(url, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  updateCampaignStatus(
    campaignId: number,
    updatedCampaign: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUri}/${campaignId}`,
      updatedCampaign,
      { withCredentials: true }
    );
  }

  setSelectedCampaign(campaign: any) {
    this.selectedCampaign.next(campaign);
  }

  getCampaignStats(campaignPublic: string): Observable<any> {
    const url = `${environment.apiUri}/campaigns/statistics/${campaignPublic}`;
    return this.http.get<any>(url, { withCredentials: true }).pipe(
      map((response) => {
        const stats = response?.data;
        if (!stats || typeof stats !== 'object') {
          throw new Error('Invalid response structure: data is not an object');
        }
  
        if (!stats.collection) {
          throw new Error('Invalid response structure: collection is undefined');
        }
  
        return {
          collection: stats.collection,
          created: stats.created,
          period: stats.period,
        };
      }),
      catchError((error) => {
        console.error('An error occurred while fetching campaign stats:', error);
        return this.handleError(error);
      })
    );
  }
  
  retryContact(publicId: string): Observable<any> {
    const url = `${environment.apiUri}/campaigns/retry/${publicId}`;
    return this.http.post<any>(url, {}, { withCredentials: true }).pipe(
      catchError(this.handleError)
    );
  }

  loadUserPreferences(userId: number, preferenceKey: string): Observable<any> {
    const url = `${this.apiUserBaseUri}/preferences/${userId}?preferenceKey=${preferenceKey}`;
    return this.http.get<any>(url).pipe(
      map((response) => response || {}),
      catchError(this.handleError)
    );
  }

  saveUserPreferences(userId: number, preferenceKey: string, preferenceValue: any): Observable<any> {
    const url = `${this.apiUserBaseUri}/preferences/${userId}`;
    return this.http.post<any>(url, {
      preferenceKey,
      preferenceValue,
    }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error("An error occurred:", error);
    return throwError("Something bad happened; please try again later.");
  }
}
