import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Pipe({
  name: 'timezoneDate'
})
export class TimezoneDatePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: string | Date, format: string = 'YYYY-MM-DD HH:mm'): string {
    const user = this.authService.currentUserValue;
    const timezone = user?.timezone || moment.tz.guess();

    const utcDate = moment.utc(value);
    const localDate = utcDate.tz(timezone);

    return localDate.format(format);
  }
}
